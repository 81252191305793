<template>
  <div>
    <div class="page-content py-5">
      <div class="container">
        <!--register-->
        <form @submit.prevent="Register()">
          <div
            class="
              edc-content-round
              register-content
              rounded-50
              col-lg-5 col-sm-12
              m-auto
              p-3
            "
          >
            <div class="user-form">
              <div
                class="
                  user-form-header
                  mb-4
                  text-right
                  d-block
                  border-bottom border-1
                  pb-2
                "
              >
                <h1 class="h3">{{ $t("title.register_new_donor") }}</h1>
                <div class="text-muted fs-7 mt-3">
                  {{ $t("message.register_message") }}
                </div>
              </div>
              <div class="alert alert-danger" v-if="errorMessage">
                {{ errorMessage }}
              </div>
              <div class="text-muted fs-7 my-3 edc-color-secondary">
                {{ $t("label.personal_info") }}
              </div>
              <div class="input-group mb-3">
                <span
                  class="
                    input-group-text
                    edc-bg-primary
                    rounded-pill rounded-start
                    px-3
                  "
                  id="name"
                >
                  <i class="fas fa-user" aria-hidden="true"></i>
                </span>
                <input
                  type="text"
                  class="form-control rounded-pill rounded-end"
                  v-model="form.name"
                  :placeholder="$t('label.name')"
                  aria-label="name"
                  aria-describedby="name"
                />
              </div>
              <div class="input-group mb-3">
                <span
                  class="
                    input-group-text
                    edc-bg-primary
                    rounded-pill rounded-start
                    px-3
                  "
                  id="mobile"
                >
                  <i class="fas fa-phone" aria-hidden="true"></i>
                </span>
                <input
                  type="tel"
                  v-model="form.mobile"
                  :placeholder="$t('label.mobile')"
                  class="form-control rounded-pill rounded-end"
                  :class="{ 'is-invalid': isDisplayError('mobile') }"
                  aria-label="mobile"
                  aria-describedby="mobile"
                />
              </div>
              <div class="text-muted fs-7 my-3 edc-color-secondary">
                {{ $t("label.user_info") }}
              </div>
              <div class="input-group mb-3">
                <span
                  class="
                    input-group-text
                    edc-bg-primary
                    rounded-pill rounded-start
                    px-3
                  "
                  id="username"
                >
                  <i class="fas fa-user" aria-hidden="true"></i>
                </span>
                <input
                  type="text"
                  v-model="form.username"
                  :placeholder="$t('label.username')"
                  :class="{ 'is-invalid': isDisplayError('username') }"
                  class="form-control rounded-pill rounded-end"
                  aria-label="username"
                  aria-describedby="username"
                />
              </div>
              <div class="input-group mb-3">
                <span
                  class="
                    input-group-text
                    edc-bg-primary
                    rounded-pill rounded-start
                    px-3
                  "
                  id="email"
                >
                  <i class="fas fa-envelope" aria-hidden="true"></i>
                </span>
                <input
                  type="email"
                  v-model="form.email"
                  :placeholder="$t('label.email')"
                  class="form-control rounded-pill rounded-end"
                  :class="{ 'is-invalid': isDisplayError('email') }"
                  aria-label="email"
                  aria-describedby="email"
                />
              </div>
              <div class="input-group mb-3">
                <span
                  class="
                    input-group-text
                    edc-bg-primary
                    rounded-pill rounded-start
                    px-3
                  "
                  id="password"
                >
                  <i class="fas fa-key" aria-hidden="true"></i>
                </span>
                <input
                  type="password"
                  v-model="form.password"
                  :placeholder="$t('label.password')"
                  class="form-control rounded-pill rounded-end"
                  :class="{ 'is-invalid': isDisplayError('password') }"
                  aria-label="password"
                  aria-describedby="password"
                />
              </div>
              <div class="input-group mb-3">
                <span
                  class="
                    input-group-text
                    edc-bg-primary
                    rounded-pill rounded-start
                    px-3
                  "
                  id="repet-password"
                >
                  <i class="fas fa-key" aria-hidden="true"></i>
                </span>
                <input
                  type="password"
                  v-model="form.repet_password"
                  :placeholder="$t('label.repeat_password')"
                  class="form-control rounded-pill rounded-end"
                  aria-label="repet-password"
                  :class="{ 'is-invalid': isDisplayError('repet_password') }"
                  aria-describedby="repet-password"
                />
              </div>
            </div>
            <div
              class="
                login-buttons
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <button
                type="submit"
                class="
                  btn
                  edc-btn-primary
                  flex-fill
                  text-white
                  rounded-pill
                  py-2
                  px-4
                "
              >
                {{ $t("btn.register") }}
              </button>
            </div>
          </div>
        </form>
        <!--//register-->
      </div>
    </div>
  </div>
</template>
 
<script>
import Auth from "../../auth";
import { mapActions } from "vuex";
export default {
  name: "RegisterPage",
  data() {
    return {
      form: {},
      input_errors: [],
      errorMessage: "",
    };
  },
  methods: {
    ...mapActions("auth", ["register"]),
    Register() {
      this.errorMessage = "";
      this.input_errors = [];
      this.register(this.form)
        .then((response) => {
          //console.log(response);
          Auth.setAUthData(response.data);
          this.$root.$emit("updateAuthData", Auth.getAUthData());
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error.response);
          this.errorMessage = error.response.data.message;
          this.input_errors = error.response.data.data.params;
        });
      //console.log(this.form);
    },
    isDisplayError(field) {
      return this.input_errors.includes(field);
    },
  },
  metaInfo() {
    return {
      titleTemplate: this.$t("title.register_new_donor") + " | %s",
      meta: [
        { name: "viewport", content: "user-scalable=no" },
        { property: "og:title", content: this.$t("title.register_new_donor") },
      ],
    };
  },
};
</script>